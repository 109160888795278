import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import ServiceDetailsContent from '../../components/ServiceDetails/ServiceDetailsContent'
import RelatedServices from '../../components/ServiceDetails/RelatedServices'
 
const SoftwareDevelopmentDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Software Engineering" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Software Engineering" 
            />
            <ServiceDetailsContent />
            <RelatedServices />
            <Footer />
        </Layout>
    );
}

export default SoftwareDevelopmentDetails